<template>
  <div ref="containerRef" class="container">
    <div
      class="img-item"
      ref="imgItemRef"
      v-for="(item, index) in waterData"
      :key="index"
      :style="{ width: imgWidth + 'px' }"
      @click="onClickWater(item)"
    >
      <img
        v-lazy="item.image"
        width="100%"
        @load="load"
        alt=""
        v-if="showName"
      />
      <img
        :src="item.logo"
        class="logo-img"
        width="100%"
        @load="load"
        alt=""
        v-else
      />
      <div class="item-name" v-if="showName">{{ item.title || item.name }}</div>
      <div class="item-com" @click.stop="clickCom(item)">
        <img
          :src="item.companyLogo"
          class="item-com-img"
          alt=""
          v-if="showName"
        />
        <span v-if="showName">{{ item.companyName }}</span>
        <span v-else>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgWidth: {
      type: Number,
      default: 225, //每张图片的固定宽度
    },
    waterData: {
      type: Array,
      default: () => [
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/des.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
        {
          img: require("../../assets/img/新入驻企业.png"),
          logo: require("../../assets/img/com-logo.jpg"),
          title: "XXX板子",
          name: "圳在线数字（深圳）有限公司",
        },
      ],
    },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  watch: {
    waterData(n) {
      this.load();
    },
  },
  mounted() {
    this.layout();
    //监听屏幕变化重新定位
    window.onresize = () => {
      return (() => {
        let timerId = setTimeout(() => {
          this.$nextTick(() => {
            this.layout();
          });
        }, 500);
        this.$once("hook:beforeDestroy", () => {
          clearInterval(timerId);
          timerId = null;
        });
      })();
    };
  },
  methods: {
    //点击图片
    onClickWater(item) {
      this.$emit("on-click-water", item);
    },
    //点击公司
    clickCom(item) {
      this.$emit("click-com", item);
    },
    //图片加载完后都要重新定位
    load() {
      this.$nextTick(() => {
        this.layout();
      });
    },
    cal() {
      let containerWidth = this.$refs.containerRef.clientWidth; //容器的宽度
      //计算列的数量
      let columns = Math.floor(containerWidth / this.imgWidth);
      //计算间隙
      let spaceNumber = columns + 1; //间隙数量
      let leftSpace = containerWidth - columns * this.imgWidth; //计算剩余的空间
      let space = leftSpace / spaceNumber; //每个间隙的空间
      return {
        space: space,
        columns: columns,
      };
    },
    layout() {
      let info = this.cal(); //得到列数，和 间隙的空间
      let nextTops = new Array(info.columns); //该数组的长度为列数，每一项表示该列的下一个图片的纵坐标
      nextTops.fill(0); //将数组的每一项填充为0
      for (let i = 0; i < this.$refs.containerRef.children.length; i++) {
        let img = this.$refs.containerRef.children[i];
        //找到nextTops中的最小值作为当前图片的纵坐标
        let minTop = this.getMin(nextTops);
        img.style.top = minTop + "px";
        //重新设置数组这一项的下一个top值
        let index = nextTops.indexOf(minTop); //得到使用的是第几列的top值
        nextTops[index] += img.offsetHeight + info.space;
        //横坐标
        let left = (index + 1) * info.space + index * this.imgWidth;
        img.style.left = left + "px";
      }
      let max = this.getMax(nextTops); //求最大值
      this.$refs.containerRef.style.height = max + "px"; //3. 设置容器的高度
    },
    // 得到该数组中的最大值
    getMax(arr) {
      let max = arr[0];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i] > max) {
          max = arr[i];
        }
      }
      return max;
    },
    // 得到该数组中的最小值
    getMin(arr) {
      let min = arr[0];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i] < min) {
          min = arr[i];
        }
      }
      return min;
    },
  },
};
</script>
<style lang='scss' scoped>
.container {
  // margin: 10px;
  position: relative;

  .img-item {
    position: absolute;
    transition: 0.3s;
    background-color: #fff;
    box-shadow: 0 2px 12px rgb(96 101 108 / 10%);
    transition: all 0.2s linear;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    }
    .logo-img {
      width: 100%;
      height: 150px;
      object-fit: contain;
      margin: 10px 0;
    }
    .item-name {
      font-size: 16px;
      text-align: left;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      display: -webkit-box;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //隐藏后添加省略号
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; //想显示多少行
    }

    .item-com {
      @include flexbox(row);
      font-size: 12px;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      .item-com-img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        margin-right: 5px;
      }
    }
  }
}
</style>